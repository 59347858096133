<template>
<div>
    <b-navbar class='nav-on-top' toggleable="lg" type="dark" variant="dark" >
        <b-navbar-brand href="#/fisa_intrare_produse" >
            <div class="logo-pitcar">
                <img src="../assets/logo-pitcar-navbar.png" alt="logo" style="max-height: 20px;" /> 
            </div>
                       
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" class="menu-bar" is-nav>

             <!-- start aici inseram restul de elemente din meniu -->             
            <b-navbar-nav v-if="!$este_tip_utilizator('mecanic')">
                <b-nav-item href='#/crm' class='crm'> Auto Serv </b-nav-item>
                <b-nav-item href='#/programari' class='crm'> Programari </b-nav-item>
                <b-nav-item-dropdown text="Settings">
                    <b-dropdown-item href='#/categorii_roti' class='categorii_roti'> Categorii roti </b-dropdown-item>
                    <b-dropdown-item href='#/preturi_depozitare_roti' class='preturi_depozitare_roti'> Preturi depozitare roti </b-dropdown-item>
                    <b-dropdown-item href='#/verificari_checklist' class='verificari_checklist'> Verificari checklist </b-dropdown-item>
                    <b-dropdown-item href='#/clienti' class='clienti'> Clienti </b-dropdown-item>
                    <b-dropdown-item href='#/masini' class='masini'> Masini </b-dropdown-item>
                    <b-dropdown-item href='#/produse' class='produse'> Produse </b-dropdown-item>
                    <b-dropdown-item href='#/tip_produse_rapide' class='tip_produse_rapide'> Tip produse rapide </b-dropdown-item>
                    <b-dropdown-item href='#/serii' class='serii'> Serii </b-dropdown-item>
                    <b-dropdown-item href='#/sms_template' class='sms_template'> SMS Template </b-dropdown-item>
                </b-nav-item-dropdown>         
                <b-nav-item-dropdown text="Others">
                    <b-dropdown-item href='#/checklist' class='checklist'> Checklist </b-dropdown-item> 
                    <b-dropdown-item href='#/facturi' class='facturi'> Facturi </b-dropdown-item>
                    <b-dropdown-item href='#/oferte' class='oferte'> Oferte </b-dropdown-item> 
                    <b-dropdown-item href='#/devize' class='devize'> Devize </b-dropdown-item> 
                    <b-dropdown-item href='#/fisa_intrare' class='fisa_intrare'> Fisa intrare </b-dropdown-item>
                    <b-dropdown-item href='#/fisa_intrare_settings' class='fisa_intrare_settings'> Settings fisa intrare </b-dropdown-item>                 
                    <b-dropdown-item href='#/inspectie_finala_settings' class='inspectie_finala_settings'> Settings inspectie finala </b-dropdown-item>                 
                </b-nav-item-dropdown>                       
                <b-nav-item-dropdown text="Reports">
                    <b-dropdown-item href='#/reports/car-count' class='car-count'> Car Count </b-dropdown-item> 
                    <b-dropdown-item href='#/reports/salary' class='salary'> Salary </b-dropdown-item> 
                </b-nav-item-dropdown>    
                <b-nav-item href='#/hotel_anvelope' class='hotel_anvelope'> Hotel anvelope </b-nav-item>                   
                <!-- end aici inseram restul de elemente din meniu -->
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown text="Users" right v-if="$este_tip_utilizator('admin')"> 
                    <b-dropdown-item href="#/utilizatori">Utilizatori</b-dropdown-item>
                    <b-dropdown-item href="#/grupuri-utilizatori">Grupuri utilizatori</b-dropdown-item>
                    <b-dropdown-item href="#/categorii-drepturi">Categorii drepturi</b-dropdown-item>
                    <b-dropdown-item href="#/drepturi">Drepturi</b-dropdown-item>
                    <b-dropdown-item    down-item @click="change_password()"> Password</b-dropdown-item>
                </b-nav-item-dropdown>
                
                <b-nav-item v-if="!$este_tip_utilizator('mecanic')" @click="change_password()"> Password</b-nav-item>

                <!-- user mecanic -->                     
                <b-navbar-nav v-if="$este_tip_utilizator('mecanic')">
                    <b-nav-item  href='#/fisa_intrare_produse' > Dashboard </b-nav-item>
                    <b-nav-item href='#/servicii_ultrarapide' > Servicii ultrarapide </b-nav-item>
                </b-navbar-nav>           
                <!--  -->
                
                <b-nav-item @click="onLogout()"  right> <b>{{user_name}}</b>  Log out (v.{{version}})</b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
    <change-password-dialog ref="changePassDlg"></change-password-dialog>
</div>

</template>

<script>

import settings from '@/backend/LocalSettings'; 
import ChangePassword_dialog from '@/pages/ChangePassword_dialog';
import BasePage from "@/pages/BasePage";
import { async } from 'q';

export default {
    name: 'Login',
    extends: BasePage,
    data () {
        return {
            user_type: '',
            version: '',
            user_name: ''
        }
    },
    components: {
        'change-password-dialog': ChangePassword_dialog
    },
    methods:
        {
            post: async function(url, args={}){
                this.loadingVisible      = true;
                var             response = await this.$http.post(url, args );
                this.loadingVisible      = false;
                if( settings.verify_response(response) )
                {
                    return response.body;
                }
                else
                {
                    this.$router.push("/");
                }
            },

            change_password: function(){
                this.$refs['changePassDlg'].show_me();
            },

            handleSelect(item)
            {
                
                if(item === 'adauga_fisa') {
                    this.$refs['fisaIntrareDlg'].show_me();
                    //console.log(this.$refs)
                }
            },

            async onLogout()
            {
                settings.logout();
                this.$router.push("/");
                window.location.reload()
            }
        },
    mounted()
    {
        this.user_type      = settings.get_user_type();
        this.version        = settings.version;
        this.user_name      = settings.get_user_name();
        
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.nav-on-top{
    z-index:1000;
}

.navbar{
    margin-top: 20px;
}

.bg-dark{
    background-color: #152e33 !important;
}

.navbar-dark .navbar-nav .nav-link{
    color: #E6A23C;
    text-transform: uppercase;
    font-size: 12px;
}

.navbar-dark .active .nav-link{
    background-color: #0d7aa3;
    color: white;
}

.logo-pitcar{
    background-size: auto; /* Resize the background image to cover the entire container */
    position: relative;
    text-align: center;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

</style>
